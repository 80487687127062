<template>
  <div class="card pointer" @click="jump">
    <div class="img" :style="`background:url(${obj.coverImg});background-size: cover;`"></div>
    <div class="detail">
      <div class="title">{{obj.courseName}}</div>
      <div class="level"><span>{{formatter(obj.difficulty)}}</span><span> {{obj.viewNum}}</span>人已看</div>
      <div class="flex justify-between foot">
        <div class="btn">立即{{type==='lesson'?'学习':'做题'}}</div>

        <span @click.stop="handleCollent">
          <div class="collect" v-if="!obj.isCollent">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shoucang"></use>
            </svg>
            收藏
          </div>
          <div class="collect" v-else>
            <svg class="icon" aria-hidden="true" style="color: #db3b3d">
              <use xlink:href="#icon-shoucang_active"></use>
            </svg>
            已收藏
          </div>
        </span>

      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/img/com/card-default.png'
export default {
  // 卡片
  props: {
    type: {
      type: String,
      default: 'lesson'
    },
    obj: {
      type: Object,
      default: () => ({})
    },
    num: ''
  },
  data () {
    return {
      defaultImg: defaultImg,
      memberCode: '',
    }
  },
  methods: {

    handleCollent () {
      // 处理是否收藏
      this.$api.lesson.addCollect({
        memberCode: this.memberCode,
        courseCode: this.obj.courseCode
      }).then(res => {
        if (res.data.code === 200) {
          this.obj.isCollent = !this.obj.isCollent
        }
      })
    },

    jump () {
      let temp = {}
      if (this.type === 'lesson') {
        switch (this.obj.type) {
          case '2':
            temp = {
              path: '/practice/lesson/image',
              query: {
                courseCode: this.obj.courseCode
              }
            }
            break;
          case '1':
            temp = {
              path: '/practice/lesson/video',
              query: {
                courseCode: this.obj.courseCode
              }
            }
            break;
          default:
            break;
        }
      } else {
        temp = {
          path: '/practice/exercise/log',
          query: {
            courseCode: this.obj.courseCode
          }
        }
      }
      this.$router.push(temp)
    },
    formatter (val) {
      //难度格式化
      switch (val) {
        case '1':
          return '零基础';
        case '2':
          return '初阶';
        case '3':
          return '进阶';
        case '4':
          return '高阶';
        default:
          break;
      }
    }
  },

  created () {
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
  }




}
</script>

<style lang="less" scoped>
.card {
  text-align: left;
  width: 228px;
  height: 270px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 0 20px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  .img {
    width: 228px;
    height: 170px;
  }
  .detail {
    padding: 10px;
    .title {
      line-height: 30px;
    }
    .level {
      font-size: 12px;
      color: #7f7f7f;
    }
    .foot {
      margin-top: 6px;
      .btn {
        width: 76px;
        height: 20px;
        line-height: 20px;
        color: #fff;
        background: #db3b3d;
        font-size: 12px;
        text-align: center;
      }
      .collect {
        font-size: 12px;
        color: #7f7f7f;
      }
    }
  }
}
</style>