<template>
  <div id="practiceLesson">
    <!-- 快速导航 -->
    <section class="quicklyNav">
      <div class="quicklyNav_title">
        <div class="title_left">
          <div class="title_img">
            <el-image :src="require('../../../assets/img/practice/allLessons.png')" style="height: 100%; width: 100%" />
          </div>
          <div class="title_text">全部{{type==='lesson'?'课程':'练习'}}</div>
        </div>
        <div class="title_search">
          <el-input placeholder="搜索感兴趣的内容" v-model="queryInfo.courseName">
            <el-button slot="append" icon="el-icon-search" @click="_getList()"></el-button>
          </el-input>
        </div>
      </div>
      <div class="quicklyNav_lessons">
        <div class="navType">
          <div class="navType_title">方向：</div>
          <div class="navType_content">
            <template v-for="(item, index) in directionList">
              <div class="navType_item pointer" :class="[index == nowDireIndex ? 'active_item' : '']" :key="index"
                @click="changeDirection(index,item)">
                {{ item.categoryName }}
              </div>
            </template>
          </div>
        </div>
        <div class="navType">
          <div class="navType_title">分类：</div>
          <div class="navType_content">
            <template v-for="(item, index) in classifyList">
              <div class="navType_item pointer" :class="[index == nowXClasIndex ? 'active_item' : '']" :key="index"
                @click="changeClassify(index,item)">
                {{ item.categoryName }}
              </div>
            </template>
          </div>
        </div>
        <div class="navType">
          <div class="navType_title">难度：</div>
          <div class="navType_content pointer">
            <template v-for="(item, index) in difficultyList">
              <div class="navType_item" :class="[index == nowDiffIndex ? 'active_item' : '']" :key="index"
                @click="changeDifficulity(index,item.value)">
                {{ item.label }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
    <section class="box">
      <div class="box_title">
        <div class="title_type">
          <template v-for="(item, index) in contentType">
            <div class="type_item pointer" :class="[index == nowContIndex ? 'active_content' : '']" :key="index"
              @click="changeContentIndex(index,item.value)">
              {{ item.label }}
            </div>
          </template>
        </div>
        <div class="title_dropDown pointer">
          <el-dropdown @command="changeDrop" trigger="click">
            <span class="el-dropdown-link">
              {{ nowDropDownValue.label }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <template v-for="(item, index) in dropDownList">
                <el-dropdown-item :key="index" :command="item">{{ item.label }}</el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div class="flex flex-wrap">
        <ComCard v-for="(item, index) in lessonList" :obj="item" :key="item.id" :type="type" />
      </div>

      <div class="pageInfo">
        <el-pagination background @current-change="handleCurrentChange" :current-page.sync="queryInfo.currPage"
          :page-size="queryInfo.pageSize" layout=" prev, pager, next,total, jumper" :total="count">
        </el-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import ComCard from "@/components/com-card";
export default {
  props: {
    type: {
      type: String,
      default: 'lesson'
    }
  },
  components: {
    ComCard,
  },
  data () {
    return {
      lessonList: [

      ],
      // 分页参数
      queryInfo: {
        currPage: 1,
        pageSize: 10,
        fatherCourseCode: '',
        categoryCode: '',
        courseName: '',
        type: '',
        difficulty: '',
        reorder: '1'
      },
      count: 0,
      // 方向
      directionList: [
        {
          categoryName: "全部",
          categoryCode: null
        },
      ],
      nowDireIndex: "0",
      currDir: {},
      // 分类
      classifyList: [
        {
          categoryName: "全部",
          categoryCode: null
        },
      ],
      nowXClasIndex: "0",
      // 难度
      difficultyList: [{ label: "全部", value: null }, { label: "零基础", value: '1' }, { label: "初阶", value: '2' }, { label: "进阶", value: '3' }, { label: "高阶", value: '4' }],
      nowDiffIndex: "0",
      // 内容分类
      contentType: [{ label: "最新", value: '1' }, { label: "最多点击", value: '2' }, { label: "最新收藏", value: '3' }],
      nowContIndex: "0",
      // 下拉菜单
      dropDownList: [{ label: "全部", value: null }, { label: "图文", value: '2' }, { label: "视频", value: '1' }],
      nowDropDownValue: { label: "全部", value: null },
      memberCode: ""
    };
  },
  methods: {
    // 回到首页
    goHome () {
      this.$router.replace({
        path: "/home",
      });
    },

    //详情页
    jumpDetail () {
      // this.$router.push({
      //   path: "/practice/lesson/image",
      // });
      this.$router.push({
        path: "/practice/lesson/video",
      });
    },
    // 返回上一页
    goBack () {
      this.$router.replace({
        path: "/practice",
      });
    },
    // 修改方向
    changeDirection (index, item) {
      this.nowDireIndex = index;
      this.currDir = item
      this.queryInfo.fatherCourseCode = item.categoryCode
      this._getCategory2()
      this._getList()
    },
    // 修改分类
    changeClassify (index, item) {
      this.nowXClasIndex = index;
      this.queryInfo.categoryCode = item.categoryCode
      this._getList()
    },
    // 修改难度
    changeDifficulity (index, value) {
      this.nowDiffIndex = index;
      this.queryInfo.difficulty = value
      this._getList()
    },
    // 修改内容分类
    changeContentIndex (index, value) {
      this.nowContIndex = index;
      this.queryInfo.reorder = value
      this._getList()
    },
    changeDrop (e) {
      this.queryInfo.type = e.value
      this.nowDropDownValue = e;
      this._getList()
    },
    // 修改当前页
    handleCurrentChange (newCurr) {
      this.queryInfo.currPage = newCurr;
      this._getList()
    },

    _getCategory1 () {
      //方向
      this.$api.lesson.getCategory1().then(res => {
        if (res.data.code === 200) {
          this.directionList = []
          this.directionList = res.data.data
          this.directionList.unshift({
            categoryName: "全部",
            categoryCode: null
          })
        }
      })
    },

    _getCategory2 () {
      //分类
      this.$api.lesson.getCategory2(
        {
          parentCode: this.currDir.categoryCode
        }
      ).then(res => {
        if (res.data.code === 200) {
          this.classifyList = []
          this.classifyList = res.data.data
          this.classifyList.unshift({
            categoryName: "全部",
            categoryCode: null
          })
        }
      })
    },

    _getList () {
      //list
      this.$api.lesson.getList({
        currPage: this.queryInfo.currPage,
        pageSize: this.queryInfo.pageSize,
        memberCode: this.memberCode,
        condition: {
          fatherCourseCode: this.queryInfo.fatherCourseCode,
          categoryCode: this.queryInfo.categoryCode,
          courseName: this.queryInfo.courseName,
          type: this.queryInfo.type,
          difficulty: this.queryInfo.difficulty,
          reorder: '1'
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.lessonList = res.data.data.data
          this.count = res.data.data.count
        }
      })
    }
  },

  created () {
    this.memberCode = JSON.parse(sessionStorage.getItem('userInfo')).memberCode
    this._getCategory1()
    this._getCategory2()
    this._getList()
  }
};
</script>

<style lang="less" scoped>
.quicklyNav {
  .quicklyNav_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_left {
      display: flex;
      align-content: center;
      .title_img {
        margin-right: 10px;
        height: 36px;
        width: 36px;
      }
      .title_text {
        height: 36px;
        font-size: 20px;
        font-weight: bold;
        line-height: 36px;
        color: #191818ff;
      }
    }
    .title_search {
      width: 242px;
    }
  }
  .quicklyNav_lessons {
    margin-top: 23px;
    .navType {
      display: flex;
      margin-bottom: 20px;
      .navType_title {
        margin-right: 12px;
        font-weight: bold;
        white-space: nowrap;
        color: #191818ff;
      }
      .navType_content {
        display: flex;
        align-items: center;
        flex-flow: wrap;
        .navType_item {
          margin-right: 8px;
          padding: 0 6px;
          color: #7f8083;
        }
      }
    }
  }
}

.box {
  width: 100%;
  height: 1071px;
  background: white;
  position: relative;
  .box_title {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between;
    .title_type {
      display: flex;
      align-items: center;
      font-size: 13px;
      .type_item {
        margin-right: 20px;
        padding: 2px 12px;
        color: #7f8083;
        border-radius: 13px;
      }
      .active_content {
        color: white !important;
        background: black;
      }
    }
    .title_dropDown {
      .el-dropdown {
        font-size: 17px;
      }
    }
  }
  .pageInfo {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    // text-align: left;
  }
}
.el-dropdown-menu__item {
  font-size: 17px;
}
// 选中的导航
.active_item {
  color: #db3b3d !important;
  background: #db3b3d0d;
}
</style>
